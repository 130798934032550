import React from 'react';
import { format, parseISO } from 'date-fns';
import { QRCode } from 'react-qrcode-logo';

import { BtnRevealQrCode, Container } from './styles';

import logo from '~/assets/logos/logo.png';

interface ITicket {
  code: string;
  event: string;
  event_date: string;
  name: string;
  document: string;
  modality: string;
  category: string;
  address: string;
  email: string;
  phone: string;
  event_id: string;
  kit?: {
    color?: string;
    size?: string;
  };
}

interface ITicketProps {
  ticket: ITicket;
}

const Ticket: React.FC<ITicketProps> = ({ ticket }) => {
  return (
    <Container id={`ticket-${ticket.code}`} className="position-relative mt-3">
      <BtnRevealQrCode
        type="button"
        className="border-0 bg-transparent d-block mx-auto"
      >
        <div className="text-center p-3">
          <QRCode
            value={`Identificador: #${ticket.code};\nNome: ${
              ticket.name
            };\nDocumento: ${ticket.document || undefined};\nE-mail: ${
              ticket.email
            };\nId do evento: ${ticket.event_id};\nNome do evento: ${
              ticket.event
            };\nData do evento: ${format(
              parseISO(ticket.event_date),
              'dd/MM/yyyy'
            )};\nModalidade: ${ticket.modality};\nCategoria: ${
              ticket.category
            };\n\nInformações do Kit ${
              ticket.kit
                ? `${ticket.kit.color ? `\nCor: ${ticket.kit.color};` : ''}${
                    ticket.kit.size ? `\nTamanho: ${ticket.kit.size}` : ''
                  }`
                : '\nNão Possui Kit'
            };
                              `}
            removeQrCodeBehindLogo
            logoImage={logo}
            logoPadding={5}
            size={250}
            ecLevel="L"
          />
          <p className="mx-auto px-2 py-1 mb-0 mt-2 ident">
            Identificador <br />#{ticket.code}
          </p>
        </div>
      </BtnRevealQrCode>
      <div className="d-flex justify-content-between align-items-center">
        <div className="circle-left" />
        <div className="small-circle" />
        <div className="small-circle" />
        <div className="small-circle" />
        <div className="small-circle" />
        <div className="small-circle" />
        <div className="small-circle" />
        <div className="small-circle" />
        <div className="small-circle" />
        <div className="small-circle" />
        <div className="small-circle" />
        <div className="small-circle" />
        <div className="small-circle" />
        <div className="small-circle" />
        <div className="small-circle" />
        <div className="small-circle" />
        <div className="small-circle" />
        <div className="circle-right" />
      </div>
      <div className="d-flex justify-content-between align-items-center px-3 mt-3 mb-4">
        <h2 className="h5 mb-0">
          {ticket.event}
          <br />
          <span className="data">
            <span>Data do evento:</span>{' '}
            {format(parseISO(ticket.event_date), 'dd/MM/yyyy')}
          </span>
        </h2>
      </div>
      <div className="px-3 pb-3">
        <p className="mb-2">
          <span className="me-2">Nome: </span>
          {ticket.name}
        </p>
        <p className="mb-2">
          <span className="me-2">Documento: </span>
          ****{ticket.document.slice(4, 11)}***
        </p>
        <p className="mb-2">
          <span className="me-2">Modalidade: </span>
          {ticket.modality}
        </p>
        <p className="mb-2">
          <span className="me-2">Categoria: </span>
          {ticket.category}
        </p>

        <p className="mb-0">
          <span className="me-2">Endereço: </span>
          {ticket.address?.replace('Nº 0', 'S/N')}
        </p>
      </div>
    </Container>
  );
};

export default Ticket;
