import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 16px;
  border: 2px solid #dfdfdf;

  background: #fff;

  img {
    width: 150px;
    height: 150px;
  }

  p {
    color: #393939;
    font-size: 14px;
    font-weight: 400;
    span {
      color: #8c8c8c;
    }
  }

  .ident {
    color: #a9a9a9;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    border-radius: 8px;
    border: 1px solid #a9a9a9;
  }
`;

export const BtnRevealQrCode = styled.button`
  position: relative;

  .blur {
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;
    opacity: 0;
    visibility: hidden;

    svg {
      path {
        fill: #fff !important;
      }
    }
  }
`;
