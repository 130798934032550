/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { Container } from './styles';

import payment from '~/assets/animations/koro-loading.json';

interface ILottiePay {
  generating?: boolean;
}

const LottiePay: React.FC<ILottiePay> = ({ generating }) => {
  const [animateTitle, setAnimateTitle] = useState(0);

  useEffect(() => {
    if (animateTitle < 3) {
      const interval = setInterval(() => {
        setAnimateTitle((prevAnimateTitle) => prevAnimateTitle + 1);
      }, 2000);
      return () => clearInterval(interval);
    }
    if (animateTitle !== 3) {
      setAnimateTitle(0);
    }

    return undefined;
  }, [animateTitle]);

  useEffect(() => {
    if (generating) {
      setAnimateTitle(3);
    }
  }, [generating]);

  return (
    <Container>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-11 text-center">
            {animateTitle === 0 && (
              <span
                className={`${
                  animateTitle === 0 && 'fade-in-left'
                } d-block h2 fw-bold`}
              >
                Reservando...
              </span>
            )}
            {animateTitle === 1 && (
              <span
                className={`${
                  animateTitle === 1 && 'fade-in-top'
                } d-block h2 fw-bold`}
              >
                Pagando...
              </span>
            )}
            {animateTitle === 2 && (
              <span
                className={`${
                  animateTitle === 2 && 'fade-in'
                } d-block h2 fw-bold`}
              >
                Finalizando compra...
              </span>
            )}
            {animateTitle >= 3 && (
              <span
                className={`${
                  animateTitle >= 3 && 'fade-in'
                } d-block h2 fw-bold`}
              >
                Gerando seus ingressos...
              </span>
            )}
            <div className="anim-lottie mt-5">
              <Lottie
                options={{
                  animationData: payment,
                  autoplay: true,
                  loop: true,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={150}
                width={120}
              />
            </div>
            <p className="text-center mt-4 pt-3">
              {animateTitle >= 3
                ? 'Compra finalizada, aguarde um momento enquanto estamos gerando seus ingressos!'
                : 'Aguarde um momento enquanto finalizamos sua compra. Estamos quase prontos para te conectar à emoção do esporte!'}
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LottiePay;
