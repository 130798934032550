import React, { useCallback, useEffect, useState } from 'react';

import { Container } from './styles';

import api from '~/services/api';
import InfiniteScroll from '~/components/InfiniteScroll';
import TicketsGroup from '../TicketsGroup';

interface ITicket {
  id: number;
  event: string;
  date: string;
  address: string;
  category: string;
  status: string;
  quantity: number;
  slug: string;
  ordersItems_pit_nm_pedidx: number;
}

interface ITicketResponse {
  data: ITicket[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

const Nexts: React.FC = () => {
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);

  const handleLoadTickets = useCallback(async (pageData) => {
    try {
      const response = await api.get<ITicketResponse>('tickets/users', {
        params: {
          page: pageData,
          date: new Date(),
          status: 'income',
        },
      });

      if (pageData === 1) {
        setTickets(response.data.data);
      } else {
        setTickets((state) => [...state, ...response.data.data]);
      }
      setTotalPages(response.data.pages);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoadingMore(false);
    }
  }, []);

  useEffect(() => {
    handleLoadTickets(1);
  }, [handleLoadTickets]);

  const handleLoad = useCallback(async () => {
    if (!loadingMore) {
      setLoadingMore(true);
      if (page < totalPages) {
        await handleLoadTickets(page + 1);
        setPage(page + 1);
      } else {
        setLoadingMore(false);
      }
    }
  }, [handleLoadTickets, loadingMore, page, totalPages]);

  return (
    <Container>
      <InfiniteScroll onInfiniteLoad={handleLoad} className="infinite-scroll">
        {tickets.map((ticket) => (
          <TicketsGroup ticket={ticket} />
        ))}
      </InfiniteScroll>
    </Container>
  );
};

export default Nexts;
