import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { IoIosArrowBack } from 'react-icons/io';
import { GoPlus } from 'react-icons/go';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';
import { cpf } from 'cpf-cnpj-validator';

import axios from 'axios';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container } from './styles';
import { KrCreditCard, KrPix } from '../KoroIcons';
import AddCards from '../AddCards';
import LottiePay from './LottiePay';
import Success from '../Success';

import mastercard from '~/assets/logos/mastercard.svg';
import visa from '~/assets/logos/visa.svg';
import americanExpress from '~/assets/logos/american-express.svg';
import elo from '~/assets/logos/elo.svg';
import { useAuth } from '~/hooks/Auth';
import InputMask from '../InputMask';
import Input from '../Input';
import InputRadio from '../InputRadio';
import { useCart } from '~/hooks/Cart';

interface IPaymentMethod {
  card?: ICard;
  type: string;
  installments: number;
  document?: string;
  phone?: string;
}

interface IFormData {
  id: number;
  name: string;
  email: string;
  document?: string;
  phone?: string;
  birthdate?: string;
  gender?: string;
  address_id?: number;
  address?: {
    zipcode: string;
    street: string;
    number?: string;
    neighborhood: string;
    city: string;
    state: string;
    complement?: string;
  };
}

interface IPayment {
  payment: boolean;
  onClickBack(): void;
  onPaymentMethodSelected(paymentMethod: IPaymentMethod): void;
  success: boolean;
  onClickConfirm(): void;
  generating?: boolean;
}

interface ICard {
  id: number;
  name: string;
  number: string;
  fullNumber?: string;
  due_date: string;
  flag: string;
  cvc: string;
}

interface ICardResponse {
  data: ICard[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

const PaymentMethod: React.FC<IPayment> = ({
  payment,
  onClickBack,
  onPaymentMethodSelected,
  success,
  onClickConfirm,
  generating,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { user, updateUser } = useAuth();
  const { cart } = useCart();
  const [showAddCard, setShowAddCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState<
    ICard | undefined
  >(undefined);
  const [userData, setUserData] = useState({} as IFormData);
  const [cards, setCards] = useState<ICard[]>([]);
  const [reloadCards, setReloadCards] = useState(1);
  const [showInputDocument, setShowInputDocument] = useState(false);
  const [showInputPhone, setShowInputPhone] = useState(false);
  const [showInputBirthdate, setShowInputBirthdate] = useState(false);
  const [showInputGender, setShowInputGender] = useState(false);
  const [showInputAddress, setShowInputAddress] = useState(false);

  const loadCards = useCallback(async (pageSelected) => {
    const response = await api.get<ICardResponse>(`cards/users`, {
      params: {
        page: pageSelected,
      },
    });
    const data = response.data.data.map<ICard>((card) => {
      let flag = '';
      switch (card.flag.toLowerCase()) {
        case 'mastercard':
          flag = mastercard;
          break;
        case 'visa':
          flag = visa;
          break;
        case 'amex':
          flag = americanExpress;
          break;
        case 'elo':
          flag = elo;
          break;

        default:
          flag = '';
          break;
      }

      return {
        ...card,
        flag,
        number: `**** **** **** ${card.number}`,
      };
    });

    if (pageSelected === 1) {
      setCards(data);
    } else {
      setCards((state) => [...state, ...data]);
    }
  }, []);

  useEffect(() => {
    loadCards(1);
  }, [loadCards, reloadCards]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setDisabled(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          document: Yup.string().when(`$checkDocument`, {
            is: (checkDocument: boolean) => checkDocument,
            then: Yup.string()
              .test('cpf', 'CPF inválido', (value) =>
                cpf.isValid(value as string)
              )
              .required('O CPF é obrigatório'),
            otherwise: Yup.string(),
          }),
          phone: Yup.string().when(`$checkPhone`, {
            is: (checkPhone: boolean) => checkPhone,
            then: Yup.string().required('O celular é obrigatório'),
            otherwise: Yup.string(),
          }),
          birthdate: Yup.string().when(`$checkBirthdate`, {
            is: (checkBirthdate: boolean) => checkBirthdate,
            then: Yup.string().required('A data de nascimento é obrigatória'),
            otherwise: Yup.string(),
          }),
          gender: Yup.string().when(`$checkGender`, {
            is: (checkGender: boolean) => checkGender,
            then: Yup.string().required('O gênero é obrigatório'),
            otherwise: Yup.string(),
          }),
          address: Yup.object().when(`$checkAddress`, {
            is: (checkAddress: boolean) => checkAddress,
            then: Yup.object().shape({
              zipcode: Yup.string().required('O CEP é obrigatório'),
              street: Yup.string().required('O endereço é obrigatório'),
              number: Yup.string().required('O número é obrigatório'),
              neighborhood: Yup.string().required('O bairro é obrigatório'),
              city: Yup.string().required('A cidade é obrigatório'),
              state: Yup.string().required('O estado é obrigatório'),
            }),
            otherwise: Yup.object().shape({
              zipcode: Yup.string(),
              street: Yup.string(),
              number: Yup.string(),
              neighborhood: Yup.string(),
              city: Yup.string(),
              state: Yup.string(),
            }),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            checkDocument:
              paymentMethodSelected?.id === -1 && showInputDocument,
            checkPhone: paymentMethodSelected?.id === -1 && showInputPhone,
            checkBirthdate:
              paymentMethodSelected?.id === -1 && showInputBirthdate,
            checkGender: paymentMethodSelected?.id === -1 && showInputGender,
            checkAddress: paymentMethodSelected?.id === -1 && showInputAddress,
          },
        });

        let address_id = user.additionalInformation.address?.id;
        if (showInputAddress) {
          const addressFormData = {
            zip_code: data.address.zipcode,
            street: data.address.street,
            number: data.address.number,
            neighborhood: data.address.neighborhood,
            city: data.address.city,
            state: data.address.state,
            complement: data.address.complement,
          };

          if (!user.additionalInformation.address) {
            const response = await api.post(`addresses`, addressFormData);
            address_id = response.data.id;
          }
        }

        const athleteFormData = {
          user_id: user.id,
          address_id,
          document: user.additionalInformation.document || data.document,
          phone: user.additionalInformation.phone || data.phone,
          birthdate: user.additionalInformation.birthdate || data.birthdate,
          gender: user.additionalInformation.gender || data.gender,
        };

        await api.put(
          `additional-informations/${user.additionalInformation.id}`,
          athleteFormData
        );

        updateUser({
          ...user,
          additionalInformation: {
            ...user.additionalInformation,
            document: user.additionalInformation.document || data.document,
            phone: user.additionalInformation.phone || data.phone,
            birthdate: user.additionalInformation.birthdate || data.birthdate,
            gender: user.additionalInformation.gender || data.gender,
            address: {
              ...user.additionalInformation.address,
              street:
                user.additionalInformation.address?.street ||
                data.address.street,
              number:
                user.additionalInformation.address?.number ||
                data.address.number,
              neighborhood:
                user.additionalInformation.address?.neighborhood ||
                data.address.neighborhood,
              city:
                user.additionalInformation.address?.city || data.address.city,
              state:
                user.additionalInformation.address?.state || data.address.state,
              complement:
                user.additionalInformation.address?.complement ||
                data.address.complement,
              zip_code:
                user.additionalInformation.address?.zip_code ||
                data.address.zipcode,
              country: 'BR',
            },
          },
        });

        setLoading(true);

        if (paymentMethodSelected) {
          if (paymentMethodSelected.id === -1) {
            onPaymentMethodSelected({
              type: 'PIX',
              installments: 1,
              document: data.document,
              phone: data.phone,
            });
          } else {
            onPaymentMethodSelected({
              card: paymentMethodSelected,
              type: 'CREDITO',
              installments: 1,
            });
          }
        }
        setDisabled(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);

          formRef.current?.setErrors(errors);
          setDisabled(false);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
          setDisabled(false);
        }
      }
    },
    [
      onPaymentMethodSelected,
      paymentMethodSelected,
      showInputAddress,
      showInputBirthdate,
      showInputDocument,
      showInputGender,
      showInputPhone,
      updateUser,
      user,
    ]
  );

  const handleSucceededAddCard = useCallback((card) => {
    if (Object.keys(card).length === 0) {
      setReloadCards((state) => state + 1);
    } else {
      let flag = '';
      switch (card.flag.toLowerCase()) {
        case 'mastercard':
          flag = mastercard;
          break;
        case 'visa':
          flag = visa;
          break;
        case 'amex':
          flag = americanExpress;
          break;
        case 'elo':
          flag = elo;
          break;

        default:
          flag = '';
          break;
      }

      setCards((state) => [
        ...state,
        {
          id: card.card_id,
          name: card.name,
          number: `**** **** **** ${card.number.slice(-4)}`,
          fullNumber: card.number,
          due_date: card.due_date,
          flag,
          cvc: card.cvc,
        },
      ]);
      setPaymentMethodSelected({
        id: card.card_id,
        name: card.name,
        number: `**** **** **** ${card.number.slice(-4)}`,
        fullNumber: card.number,
        due_date: card.due_date,
        flag,
        cvc: card.cvc,
      });
    }
    setShowAddCard(false);
  }, []);

  const handleSelectPixMethod = useCallback(() => {
    if (!user.additionalInformation.document) {
      setShowInputDocument(true);
    }
    if (!user.additionalInformation.phone) {
      setShowInputPhone(true);
    }
    if (!user.additionalInformation.birthdate) {
      setShowInputBirthdate(true);
    }
    if (!user.additionalInformation.gender) {
      setShowInputGender(true);
    }
    if (!user.additionalInformation.address) {
      setShowInputAddress(true);
    }
    setPaymentMethodSelected({
      id: -1,
      due_date: '',
      name: '',
      number: '',
      flag: '',
      cvc: '',
    });
  }, [
    user.additionalInformation.address,
    user.additionalInformation.birthdate,
    user.additionalInformation.document,
    user.additionalInformation.gender,
    user.additionalInformation.phone,
  ]);

  const handleChangeZipCode = useCallback(async (e) => {
    const { value } = e.target;
    if (value.length === 9) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      );
      setUserData((state) => ({
        ...state,
        address: {
          zipcode: value,
          street: response.data.logradouro,
          neighborhood: response.data.bairro,
          city: response.data.localidade,
          state: response.data.uf,
        },
      }));
    }
  }, []);

  return (
    <>
      {payment && (
        <Container active={success}>
          {showAddCard ? (
            <AddCards onSucceededAddCard={handleSucceededAddCard}>
              <button
                className="btn-back d-flex align-items-center bg-transparent border-0"
                type="button"
                onClick={() => setShowAddCard(false)}
              >
                <IoIosArrowBack color="#878787" size={12} className="me-2" />{' '}
                Voltar
              </button>
            </AddCards>
          ) : (
            <>
              {!loading ? (
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-11 text-center pt-3">
                      <button
                        type="button"
                        onClick={onClickBack}
                        className="btn-back d-flex align-items-center bg-transparent border-0"
                      >
                        <IoIosArrowBack
                          color="#878787"
                          size={12}
                          className="me-2"
                        />{' '}
                        Voltar
                      </button>

                      <div className="d-flex align-items-center justify-content-between my-3">
                        <h2 className="h4 mb-0 d-flex align-items-center">
                          <KrCreditCard
                            size={20}
                            color="#FF6900"
                            className="me-2"
                          />
                          Método de pagamento
                        </h2>
                      </div>
                      <h4 className="text-start mb-3">
                        Escolha o método de pagamento para finalizar sua compra.
                      </h4>
                    </div>

                    <div className="col-11">
                      <Form
                        ref={formRef}
                        onSubmit={handleSubmit}
                        className="pb-3"
                      >
                        {cards.map((card) => (
                          <button
                            type="button"
                            onClick={() => setPaymentMethodSelected(card)}
                            className="credtcard w-100 border-0 border-bottom d-flex align-items-center p-2 mt-3 pb-3"
                          >
                            <div className="me-3">
                              <img src={card.flag} alt="Logo cartão" />
                            </div>
                            <div>
                              <h4 className="mb-0">{card.name}</h4>
                              <p className="mb-0">{card.number}</p>
                            </div>
                            <div
                              className={`${
                                paymentMethodSelected?.id === card.id
                                  ? 'active'
                                  : 'not-active'
                              } ms-auto`}
                            />
                          </button>
                        ))}

                        <div className="text-center mt-3 pb-3 border-bottom">
                          <button
                            type="button"
                            onClick={() => setShowAddCard(true)}
                            className="btn-addcard border-0"
                          >
                            <GoPlus size={16} color="#000" className="me-2" />
                            Adicionar novo cartão
                          </button>
                        </div>

                        <button
                          type="button"
                          onClick={handleSelectPixMethod}
                          className="btn-pix w-100 border-0 border-bottom d-flex align-items-center p-2 mt-3 pb-3"
                        >
                          <div className="me-3">
                            <KrPix size={24} color="#FF6900" />
                          </div>
                          <div>
                            <h4 className="mb-0">Gerar Pix copia e cola</h4>
                          </div>
                          <div
                            className={`${
                              paymentMethodSelected?.id === -1
                                ? 'active'
                                : 'not-active'
                            } ms-auto`}
                          />
                        </button>
                        {paymentMethodSelected?.id === -1 && showInputDocument && (
                          <div className="my-2">
                            <span className="label">CPF</span>
                            <InputMask
                              kind="cpf"
                              name="document"
                              className="form-control"
                            />
                          </div>
                        )}
                        {paymentMethodSelected?.id === -1 && showInputPhone && (
                          <div className="my-2">
                            <span className="label">Celular</span>
                            <InputMask
                              kind="cel-phone"
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        )}
                        {paymentMethodSelected?.id === -1 &&
                          showInputBirthdate && (
                            <label className="mb-2 w-100">
                              <span className="label">Data de nascimento</span>
                              <Input
                                type="date"
                                name="birthdate"
                                className="bd-input"
                              />
                            </label>
                          )}
                        {paymentMethodSelected?.id === -1 && showInputGender && (
                          <div className="my-2">
                            <span className="label">Gênero</span>
                            <InputRadio
                              name="gender"
                              className="d-flex justify-content-start check"
                              options={[
                                {
                                  id: 'M',
                                  value: 'Masculino',
                                },
                                {
                                  id: 'F',
                                  value: 'Feminino',
                                },
                              ]}
                            />
                          </div>
                        )}

                        <div
                          className={`${
                            paymentMethodSelected?.id === -1 && showInputAddress
                              ? ''
                              : 'd-none'
                          }`}
                        >
                          <h2 className="h5 my-3">Endereço</h2>

                          <div>
                            <span className="label">CEP</span>
                            <InputMask
                              kind="zip-code"
                              name="address.zipcode"
                              className="form-control"
                              value={userData.address?.zipcode}
                              onChange={handleChangeZipCode}
                            />
                          </div>
                          <div>
                            <span className="label">Endereço</span>
                            <Input
                              name="address.street"
                              className="form-control"
                              defaultValue={userData.address?.street}
                            />
                          </div>
                          <div>
                            <span className="label">Numero</span>
                            <Input
                              name="address.number"
                              className="form-control"
                              defaultValue={userData.address?.number}
                            />
                          </div>
                          <div>
                            <span className="label">Bairro</span>
                            <Input
                              name="address.neighborhood"
                              className="form-control"
                              defaultValue={userData.address?.neighborhood}
                            />
                          </div>
                          <div>
                            <span className="label">Cidade</span>
                            <Input
                              name="address.city"
                              className="form-control"
                              defaultValue={userData.address?.city}
                            />
                          </div>
                          <div>
                            <span className="label">Estado</span>
                            <Input
                              name="address.state"
                              className="form-control"
                              defaultValue={userData.address?.state}
                            />
                          </div>
                          <div>
                            <span className="label">Complemento</span>
                            <Input
                              name="address.complement"
                              className="form-control"
                              defaultValue={userData.address?.complement}
                            />
                          </div>
                        </div>

                        <button
                          type="submit"
                          disabled={!paymentMethodSelected || disabled}
                          className={`${
                            !paymentMethodSelected && 'opacity-50'
                          } w-100 btn btn-login h6 fw-normal py-2 mt-3 mb-0`}
                        >
                          Pagar
                        </button>
                      </Form>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {!success ? (
                    <LottiePay generating={generating} />
                  ) : (
                    <div className="row flex-column justify-content-center align-items-center vh-100">
                      <div className="col-11 text-center success">
                        <Success
                          title={`${
                            cart.isFillBeforeTicket
                              ? 'Ingressos reservados com sucesso!'
                              : 'Nomeie seu ingresso para confirmar a inscrição'
                          }`}
                          active={success}
                          onBtnClick={onClickConfirm}
                          text={`${
                            cart.isFillBeforeTicket
                              ? ''
                              : 'Pagamento em análise, siga os próximos passos.'
                          }`}
                          textButton={`${
                            cart.isFillBeforeTicket ? 'Okay!' : 'Nomear agora'
                          }`}
                          paymentMethodId={paymentMethodSelected?.id}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default PaymentMethod;
