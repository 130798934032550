import { darken, lighten } from 'polished';
import styled from 'styled-components';

interface IBanner {
  src: string;
  first: boolean;
}

interface IColor {
  color: string;
}

interface IShareBox {
  show: boolean;
}

interface ICircle {
  color: string;
  isInstagram?: boolean;
}

interface IColorBox {
  color: string;
  selected?: boolean;
}

interface IImg {
  src: string;
}

export const Container = styled.div`
  background-color: #f4f0ec;
  min-height: 100vh;
  h1 {
    color: #000;
    font-family: 'Poppins';
    font-size: 26px;
    font-weight: 500;
  }

  h2 {
    color: #292623;
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 500;
  }

  iframe {
    width: 100%;
    height: 120px;
    border-radius: 8px;
    border: 1px solid #e1e1e1;
  }
  .address {
    min-width: 14px;
  }

  .w-social {
    min-width: 235px;
    h5 {
      color: #000;
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: 400;
    }
    p {
      color: #939393;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 400;
    }
    img {
      border-radius: 4px;
    }
    span {
      color: #939393;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 400;
    }
  }

  .description-event {
    max-height: 200px;
    overflow: hidden;
  }

  .bg-btn-see-more {
    margin-top: -40px;
    position: relative;
    z-index: 1;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 45%,
      rgba(255, 255, 255, 0.5) 100%
    );
  }

  .btn-see-more {
    border-radius: 4px;
    background-color: transparent;
    color: #ff6900;
    border: 1px solid #ff6900;
  }

  .description-event p {
    margin-bottom: 0;
  }

  .btn-login {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
    position: sticky;
    bottom: 16px;
    width: 100%;
    z-index: 100;
  }

  .btn-disabled {
    border-radius: 4px;
    background-color: #f00;
    color: #fff;
    position: sticky;
    bottom: 16px;
    width: 100%;
    z-index: 100;

    pointer-events: none;
  }

  .not-bar-scroll {
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 4px;
    }
  }

  .bd-mod {
    border-radius: 4px;
    border: 1px solid #e1e1e1;

    + .bd-mod {
      margin-top: 16px;
    }
  }

  .bd-mod span {
    font-size: 14px;
    color: #8c8c8c;
    font-weight: 400;
  }

  .qtd-kits {
    border: 1px solid #e1e1e1;
    padding: 5px 3px;
    width: 30px;
    height: 30px;
  }

  .qtd-kits input {
    color: #292623;
    text-align: center;
    font-size: 14px;
  }

  .qtd-kits svg {
    display: none;
  }

  .kits {
    border-radius: 8px;
    border: 1px solid #e1e1e1;
    min-width: 260px;
  }

  .products {
    .btn-color {
      + .btn-color {
        margin-left: 5px;
      }
    }
  }

  .w-tam {
    width: 32px;
    span {
      color: #e1e1e1;
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 400;
      border-radius: 4px;
      border: 1px solid #e1e1e1;
      padding: 3px 0;
      text-align: center;
    }

    span.active {
      color: #8c8c8c;
      border: 1px solid #8c8c8c;
    }
  }

  .color1 {
    border-radius: 4px;

    background: #41a8bd;
    width: 24px;
    height: 24px;
  }

  .border-color1 {
    border: 2px solid #a0d3de;
    width: 28px;
    height: 28px;
  }

  .color2 {
    border-radius: 4px;

    background: #f27a0b;
    width: 24px;
    height: 24px;
  }

  .border-color2 {
    border: 2px solid #f9bd85;
    width: 28px;
    height: 28px;
  }

  .color3 {
    border-radius: 4px;

    background: #021d23;
    width: 24px;
    height: 24px;
  }

  .border-color3 {
    border: 2px solid #808e91;
    width: 28px;
    height: 28px;
  }

  .title span {
    color: #fff;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
    background: #00a3e0;
    padding: 3px 8px;
    text-align: center;
  }
  .btn-back {
    position: absolute;
    top: 20px;
    left: 16px;
    z-index: 1;
    color: #fff;
    svg {
      color: #fff !important;
    }
  }

  .mt-negative {
    margin-top: -30px;

    .date-local {
      color: #8c8c8c;
      font-family: 'Poppins';
      font-size: 12px;
      font-weight: 400;
    }
  }

  p {
    color: #8c8c8c;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
  }

  .bg-white {
    position: relative;
    z-index: 1;
    border-radius: 8px;
  }

  .btn-title {
    color: #292623;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
  }

  .h-aswer {
    height: 0px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  .h-aswer-active {
    //height: 200px;
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }

  .rotate-arrow {
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
  }
  .right-arrow {
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;
  }

  .btns-banner img {
    width: 34px;
    height: 34px;
  }

  .receipt {
    position: absolute;
    top: 20px;
    right: 65px;
    z-index: 1;
  }

  .share {
    position: absolute;
    top: 20px;
    right: 16px;
    z-index: 1;
  }
  .banner-slider {
    .slick-dots {
      bottom: 40px;
      li {
        width: unset;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        button {
          background: rgba(196, 196, 196, 0.4);
          width: 10px;
          height: 10px;
          border-radius: 5px;
          transition-duration: 0.3s;

          ::before {
            display: none;
          }
        }
      }

      li.slick-active {
        button {
          width: 30px;
          background-color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
`;

export const Banner = styled.div<IBanner>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;

  :before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => (props.first ? '#00000030' : 'transparent')};
    height: 200px;
  }
`;

export const Color = styled.div<IColor>`
  border-radius: 4px;
  background: ${(props) => props.color};
  width: 24px;
  height: 24px;
`;

export const ShareBox = styled.div<IShareBox>`
  transition-duration: 0.3s;
  position: fixed;
  bottom: ${(props) => (props.show ? 0 : -150)}px;
  z-index: 1000;
  background-color: #fff;
  width: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  box-shadow: 0px 0px 10px 10px #00000029;
  max-width: 576px;

  .social-medias {
    padding-bottom: 20px;

    ::-webkit-scrollbar-thumb {
      background-color: #343a4024;
    }

    > div {
      min-width: max-content;

      button,
      a {
        + button,
        + a {
          margin-left: 10px;
        }
      }
    }
  }
`;

export const Circle = styled.div<ICircle>`
  background: ${(props) =>
    props.isInstagram
      ? `linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)`
      : props.color};
  border-radius: 50%;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ColorBox = styled.div<IColorBox>`
  min-width: 24px;
  min-height: 24px;
  border-radius: 4px;
  background: ${(props) => props.color};
  border: 2px solid
    ${(props) => (props.selected ? '#000' : darken(0.1, props.color))};
  transition-duration: 0.3s;
`;

export const Img = styled.div<IImg>`
  width: 182px;
  height: 223px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
`;
