import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';

import qrcode from '~/assets/defaults/qr-code.png';
import exclamation from '~/assets/animations/exclamation.json';
import api from '~/services/api';

interface ITicket {
  id: number;
  event: string;
  date: string;
  address: string;
  category: string;
  status: string;
  quantity: number;
  slug: string;
  ordersItems_pit_nm_pedidx: number;
}

interface ITicketsGroup {
  ticket: ITicket;
}

interface ITicketResponse {
  id: number;
  document: string;
}

const TicketsGroup: React.FC<ITicketsGroup> = ({ ticket }) => {
  const [ticketPending, setTicketPending] = useState(true);

  useEffect(() => {
    api
      .get<ITicketResponse[]>(`tickets/events/users/${ticket.id}`, {
        params: {
          allStatus: true,
        },
      })
      .then((response) => {
        const allFilled = response.data.every(
          (ticketResponse) => !!ticketResponse.document
        );

        if (allFilled) {
          setTicketPending(false);
        }
      });
  }, [ticket.id, ticket.ordersItems_pit_nm_pedidx]);

  return (
    <div className="padding-bs">
      <Link to={`${process.env.PUBLIC_URL}/meus-ingressos/${ticket.slug}`}>
        <div className="w-100 bg-ticket d-flex p-3 front mt-3">
          <div className="me-auto pe-4 pe-sm-5 align-self-center">
            <h2 className="title-h2 h6 fw-bold text-start">{ticket.event}</h2>
            <div className="d-flex">
              <div className="left-side text-start">
                <span className="d-block mb-2">Data:</span>
                <span className="d-block mb-2">Local:</span>
                <span className="d-block mb-2">Categoria:</span>
              </div>
              <div className="right-side text-start ps-2 ps-sm-4">
                <span className="d-block mb-2">
                  {format(parseISO(ticket.date), 'dd/MM/yyyy')}
                </span>
                <span className="d-block mb-2">
                  {ticket.address.replace('N˚0', 'S/N')}
                </span>
                <span className="d-block mb-2">{ticket.category || '-'}</span>
              </div>
            </div>
          </div>

          <div className="position-relative">
            {ticketPending && (
              <div className="h-100 w-100 position-absolute back-blur">
                <div className="anim-lottie">
                  <Lottie
                    options={{
                      animationData: exclamation,
                      autoplay: true,
                      loop: true,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={100}
                    width={100}
                  />
                  <span className="pending d-inline-block mt-2 text-white bg-danger rounded-pill px-3 py-1">
                    Pendências
                  </span>
                </div>
              </div>
            )}
            <img src={qrcode} alt="qrcode" />
            <p className="px-2 py-1 mb-0 mt-2">
              Identificador <br />#{ticket.id + 1000000000}
            </p>
          </div>

          <div className="position-relative circles">
            <div className="circle-top" />
            <div className="small-circle" />
            <div className="small-circle" />
            <div className="small-circle" />
            <div className="small-circle" />
            <div className="small-circle" />
            <div className="small-circle" />
            <div className="small-circle" />
            <div className="small-circle" />
            <div className="small-circle" />
            <div
              className={`circle-bottom btn-circle-bottom ${
                ticket.quantity > 1 ? 'white-bg' : ''
              }`}
            />
          </div>
        </div>

        {Array.from({
          length: ticket.quantity - 1 > 2 ? 2 : ticket.quantity - 1,
        }).map((_, index) => (
          <div className={`bg-ticket d-flex p-3 behind${index + 1}`}>
            <div className="me-auto pe-4 pe-sm-5">
              <h2 className="h6 fw-bold mb-3">Evento</h2>
              <div className="d-flex">
                <div className="left-side">
                  <span className="d-block mb-2">Data:</span>
                  <span className="d-block mb-2">Local:</span>
                  <span className="d-block mb-2">Categoria:</span>
                  <span className="d-block">Tipo:</span>
                </div>
                <div className="right-side ps-2 ps-sm-4">
                  <span className="d-block mb-2">01/08/2023</span>
                  <span className="d-block mb-2">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit
                  </span>
                  <span className="d-block mb-2">Corrida</span>
                  <span className="d-block">Saída 01</span>
                </div>
              </div>
            </div>

            <div>
              <img src={qrcode} alt="qrcode" />
              <p className="px-2 py-1 mb-0 mt-2">
                Identificador <br />#{ticket.id + 1000000000}
              </p>
            </div>

            <div className="position-relative circles">
              <div className="circle-top" />
              <div className="small-circle" />
              <div className="small-circle" />
              <div className="small-circle" />
              <div className="small-circle" />
              <div className="small-circle" />
              <div className="small-circle" />
              <div className="small-circle" />
              <div className="small-circle" />
              <div className="small-circle" />
              <div
                className={`circle-bottom ${
                  index === 0 && ticket.quantity - 1 > 1 ? 'white-bg' : ''
                }`}
              />
            </div>
          </div>
        ))}
      </Link>
    </div>
  );
};

export default TicketsGroup;
